import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  gql,
  HttpLink,
  split,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'
import { Preferences } from '@capacitor/preferences'
import { persistCacheSync } from 'apollo3-cache-persist'
import { SubscriptionClient } from 'subscriptions-transport-ws'

import { config } from '@/config'
import { getAccessToken } from '@/core/auth'
import { getAppLanguage } from '@/core/native/storage'
import { StorageWrapper } from '@/core/native/storage/storageWrapper'

import packageJson from '../../../package.json'
import { cache } from './state'

const createLink = () => {
  const authLink = setContext(async (_, { headers }) => {
    const accessToken = await getAccessToken()
    const language = (await getAppLanguage()) ?? 'ja-JP'

    if (!accessToken)
      return {
        headers: {
          ...headers,
          'x-utoniq-application-key': config.applicationKey,
          'Apollo-Require-Preflight': 'true',
          'x-utoniq-application-language': language,
        },
      }

    return {
      headers: {
        ...headers,
        'x-utoniq-application-language': language,
        authorization: `Bearer ${accessToken}`,
        'x-utoniq-application-key': config.applicationKey,
        'Apollo-Require-Preflight': 'true',
      },
    }
  })

  const httpLink = new HttpLink({
    uri: config.coreApiUrl,
    fetch,
  })

  /**
   * @description
   * - 現状graphql-wsでは動作せず、subscriptions-transport-wsを使う必要がある
   * - SSR時にWebSocketLinkを生成しようとするとエラーになるのでハンドリングする。
   * https://qiita.com/mu-suke08/items/6dc353dd641e352f350e
   */
  const wsLink =
    typeof window !== 'undefined'
      ? new WebSocketLink(
          new SubscriptionClient(config.coreApiSubscriptionUrl, {
            lazy: true,
            reconnect: true,
            connectionParams: async () => {
              const idToken = await getAccessToken()
              return { idToken, appLink: config.applicationKey }
            },
          })
        )
      : null

  const splitLink =
    typeof window !== 'undefined' && wsLink != null
      ? split(
          ({ query }) => {
            const definition = getMainDefinition(query)
            return (
              definition.kind === 'OperationDefinition' &&
              definition.operation === 'subscription'
            )
          },
          wsLink,
          httpLink
        )
      : httpLink

  return ApolloLink.from([authLink, splitLink])
}

const createApolloClient = () => {
  /**
   * not use persist cache when server render
   */
  if (typeof window !== 'undefined') {
    persistCacheSync({
      cache,
      storage: new StorageWrapper(Preferences),
    })
  }

  const client = new ApolloClient({
    version: packageJson.version,
    name: packageJson.name,
    link: createLink(),
    cache,
    connectToDevTools: config.environment !== 'production',
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-and-network',
      },
    },
  })

  client.onResetStore(async () => {
    client.setLink(createLink())
  })

  return client
}

export { ApolloProvider, createApolloClient, gql }
export * from './state'
